import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import { useLayoutEffect, useState, useEffect } from "react";
// import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";
import "./App.css";
import Home from "./pages/Home/Home";
import AppHeader from "./components/AppHeader";
import Footer from "./components/Footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import MegaMenu from "./components/MegaMenu";
// import { Suspense, lazy } from 'react';

// import Salesforce from './pages/Salesforce/Salesforce';
// import MarketingCloud from './pages/Marketingcloud/MarketingCloud';

// import 'bootstrap/scss/bootstrap-grid.scss';
// import SmoothScroll from './components/SmoothScrollBox';
// import ContactUs from './pages/Contactus/ContactUs';
// import Technology from './pages/Technology/Technology'
// import Smart from './pages/Smart/Smart';
// import Digital from './pages/Digital/Digital';
// import IndustryPage from './pages/Industry/IndustryPage';
// import Cloud from './pages/Cloud/Cloud';
// import MeetTeam from './pages/MeetTeam/MeetTeam';
// import EmployeeCustomer from './pages/EmployeeCustomer/EmployeeCustomer';
// import Events from './pages/Events/Events';
// import Benefits from './pages/Benefits/Benefits';
// import Careers from './pages/Careers/Careers';
// import Commerce from './pages/Commerce/Commerce';
// import Loader from './components/Loader';
// import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
// import AnimatedCursor from "react-animated-cursor";
import ScrollArrow from "./components/ScrollArrow";
import ReactGA from "react-ga";
import Error from "./pages/Error/Error";


const TRACKING_ID = "UA-81202421-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
//import ModelPopup from './components/ModelPopup';

const loadingBox = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

//pages
const Salesforce = React.lazy(() => import("./pages/Salesforce/Salesforce"));
const SalesCloud = React.lazy(() => import("./pages/SalesCloud/SalesCloud"));
const ServiceCloud = React.lazy(() => import("./pages/ServiceCloud/ServiceCloud"));
const EcommerceCloud = React.lazy(() => import("./pages/EcommerceCloud/EcommerceCloud"));
const HealthCloud = React.lazy(() => import("./pages/HealthCloud/HealthCould"));
const Einstein = React.lazy(() => import("./pages/Einstein/Einstein"));

const MarketingCloud = React.lazy(() =>
  import("./pages/Marketingcloud/MarketingCloud")
);
const ContactUs = React.lazy(() => import("./pages/Contactus/ContactUs"));
const Technology = React.lazy(() => import("./pages/Technology/Technology"));
const Smart = React.lazy(() => import("./pages/Smart/Smart"));
const Digital = React.lazy(() => import("./pages/Digital/Digital"));
const DigitalCms = React.lazy(() => import("./pages/DigitalCms/DigitalCms"));
const DigitalEcommerce = React.lazy(() => import("./pages/DigitalEcommerce/DigitalEcommerce"));

const Mobility = React.lazy(() => import("./pages/Mobility/Mobility"));
const Lms = React.lazy(() => import("./pages/Lms/Lms"));
const Uiux = React.lazy(() => import("./pages/Uiux/Uiux"));
const Technologies = React.lazy(() => import("./pages/Technologies/Technologies"));
const Analytits = React.lazy(() => import("./pages/Analytits/Analytits"));
const Automations = React.lazy(() => import("./pages/Automations/Automations"));
const Devops = React.lazy(() => import("./pages/Devops/Devops"));
const IndustryPage = React.lazy(() => import("./pages/Industry/IndustryPage"));
const Cloud = React.lazy(() => import("./pages/Cloud/Cloud"));
const MeetTeam = React.lazy(() => import("./pages/MeetTeam/MeetTeam"));
const EmployeeCustomer = React.lazy(() =>
  import("./pages/EmployeeCustomer/EmployeeCustomer")
);
const Events = React.lazy(() => import("./pages/Events/Events"));
const Benefits = React.lazy(() => import("./pages/Benefits/Benefits"));
const Careers = React.lazy(() => import("./pages/Careers/Careers"));
const Commerce = React.lazy(() => import("./pages/Commerce/Commerce"));
const ModelPopup = React.lazy(() => import("./components/ModelPopup"));
const PrivacyPolicy = React.lazy(() =>
  import("./pages/PrivacyPolicy/PrivacyPolicy")
);
const CookiesPolicy = React.lazy(() =>
  import("./pages/CookiesPolicy/CookiesPolicy")
);
const Generative = React.lazy(() => import("./pages/GenerativeAI/Generative"));
const CyberSecurity = React.lazy(() =>
  import("./pages/CyberSecurity/CyberSecurity")
);
const Futuristic = React.lazy(() => import("./pages/Futuristic/Futuristic"));

const Sap = React.lazy(() => import("./pages/Sap/Sap"));
const Intelligent = React.lazy(() => import("./pages/Intelligent/Intelligent"));
const SolutionPage = React.lazy(() => import("./pages/Solution/SolutionPage"));
const ServicePage = React.lazy(() => import("./pages/Service/ServicePage"));


function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    loading
      ? document.querySelector("body").classList.add("loading")
      : document.querySelector("body").classList.remove("loading");
  }, [loading]);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  // useEffect(() => {
  //   // window.scrollTo(0, 0);
  //   const scroll = new LocomotiveScroll({
  //     el: document.querySelector(".App"),
  //     smooth: true,
  //     // getDirection: true,
  // mobile: {
  //   smooth: true,
  //   inertia: 0.8,
  //   getDirection: true,
  // },
  // tablet: {
  //   smooth: true,
  //   inertia: 0.8,
  //   getDirection: true,
  // },
  //     // lerp: .06,
  //     // multiplier: .5
  //   });

  //   return () => scroll.destroy();
  // }, []);
  //   if (loading) {
  //     return <Loader setLoading={setLoading} />;
  // }
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    //     <AnimateSharedLayout type="crossfade">
    // <AnimatePresence>
    // {loading ? (
    //   <motion.div key='loader'>
    //   <Loader setLoading={setLoading} />
    //   </motion.div>

    // ) : (

    <div className="App">
      {/* <SmoothScroll> */}
      <ScrollArrow />
      {/* <ModelPopup /> */}
      {/* <AnimatedCursor
      color="#61aa57"
      innerSize={10}
      outerSize={35}
      innerScale={1}
      outerScale={1.1}
      outerAlpha={0}
      hasBlendMode={true}
      outerStyle={{
        border: '2px solid',
        color:'#0f457a',
        zIndex: '1066'
        // mixBlendMode: 'multiply'
      }}
      innerStyle={{
        backgroundColor: '#61aa57',
        zIndex: '1066'
      }}

      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link',
        '.nav-link'
      ]}
    /> */}

      {/* {!loading && ( */}
      <div className="App-inner" layoutId="main-div-1">
        <Wrapper>
          <MegaMenu />
          {/* <AppHeader /> */}
          <div className="app-section">
            <React.Suspense fallback={loadingBox}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/salesforce" element={<Salesforce />} />
                <Route path="/salescloud" element={<SalesCloud />} />
                <Route path="/servicecloud" element={<ServiceCloud />} />
                <Route path="/healthcloud" element={<HealthCloud />} />
                <Route path="/ecommercecloud" element={<EcommerceCloud />} />
                <Route path="/einstein" element={<Einstein />} />
                <Route path="/industry" element={<IndustryPage />} />
                
                <Route path="/commerce" element={<Commerce />} />
                <Route path="/marketingcloud" element={<MarketingCloud />} />
                <Route path="/smart" element={<Smart />} />
                <Route path="/technology" element={<Technology />} />
                <Route path="/digital" element={<Digital />} />
                <Route path="/digitalcms" element={<DigitalCms />} />
                <Route path="/ecommerce" element={<DigitalEcommerce/>} />
                <Route path="/uiux" element={<Uiux/>} />
                <Route path="/mobility" element={<Mobility />} />
                <Route path="/technologies" element={<Technologies />} />
                <Route path="/lms" element={<Lms />} />
                <Route path="/analytics" element={<Analytits />} />
                <Route path="/automations" element={<Automations />} />
                <Route path="/devops" element={<Devops />} />
                <Route path="/cloud" element={<Cloud />} />
                <Route path="/meetourteam" element={<MeetTeam />} />
                <Route path="/employee" element={<EmployeeCustomer />} />
                <Route path="/events" element={<Events />} />
                <Route path="/benefits" element={<Benefits />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route path="/CookiesPolicy" element={<CookiesPolicy />} />
                <Route path="/generativeai" element={<Generative />} />
                <Route path="/cybersecurity" element={<CyberSecurity />} />
                <Route path="/futuristic" element={<Futuristic />} />
                <Route path="/sap" element={<Sap />} />
                <Route path="/intelligent" element={<Intelligent />} />
                <Route path="/solutions" element={<SolutionPage />} />
                <Route path="/services" element={<ServicePage />} />
                <Route path="*" element={<Error />} />
              </Routes>
            </React.Suspense>
          </div>
          <Footer />
        </Wrapper>
      </div>
      {/* //  )} */}
      {/* </SmoothScroll> */}
    </div>

    // )}
    //     </AnimatePresence>
    //     </AnimateSharedLayout>
  );
}

export default App;
